import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Hr, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				 AutoCare Pro
			</title>
			<meta name={"description"} content={"Ми в AutoCare Pro розуміємо, що ваш автомобіль – це не просто транспортний засіб, а важлива частина вашого життя. Наша місія – забезпечити його найкращим обслуговуванням, зосередившись на якості, надійності та індивідуальному підході до кожного клієнта.\n"} />
			<meta property={"og:title"} content={" AutoCare Pro"} />
			<meta property={"og:description"} content={"Ми в AutoCare Pro розуміємо, що ваш автомобіль – це не просто транспортний засіб, а важлива частина вашого життя. Наша місія – забезпечити його найкращим обслуговуванням, зосередившись на якості, надійності та індивідуальному підході до кожного клієнта.\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
		</Helmet>
		<Components.Marque>
			<Override slot="text" />
			<Override slot="text1" />
		</Components.Marque>
		<Components.Header>
			<Override slot="link3" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Section padding="100px 0 100px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-14">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/9b74b50-690-2.jpg?v=2024-09-20T08:16:48.599Z"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 0px 0px 5px"
						md-height="500px"
						srcSet="https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/9b74b50-690-2.jpg?v=2024-09-20T08%3A16%3A48.599Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/9b74b50-690-2.jpg?v=2024-09-20T08%3A16%3A48.599Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/9b74b50-690-2.jpg?v=2024-09-20T08%3A16%3A48.599Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/9b74b50-690-2.jpg?v=2024-09-20T08%3A16%3A48.599Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/9b74b50-690-2.jpg?v=2024-09-20T08%3A16%3A48.599Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/9b74b50-690-2.jpg?v=2024-09-20T08%3A16%3A48.599Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/9b74b50-690-2.jpg?v=2024-09-20T08%3A16%3A48.599Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					width="50%"
					padding="0px 0px 0px 80px"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="--headline2"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						lg-margin="0px 0px 35px 0px"
						text-align="center"
					>
						Ласкаво просимо до AutoCare Pro
					</Text>
					<Text margin="0px 0px 20px 0px" font="normal 400 20px/1.5 --fontFamily-sans">
						Ваша довіра – наша турбота
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 20px 0px"
						width="50px"
						display="flex"
						justify-content="center"
						border-width="2px 0 0 0"
						border-color="--color-darkL1"
					/>
					<Text margin="0px 0px 25px 0px" font="--base" color="--darkL1" text-align="center">
						Ми в AutoCare Pro розуміємо, що ваш автомобіль – це не просто транспортний засіб, а важлива частина вашого життя. Наша місія – забезпечити його найкращим обслуговуванням, зосередившись на якості, надійності та індивідуальному підході до кожного клієнта.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" />
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					srcSet="https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08%3A16%3A48.579Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08%3A16%3A48.579Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08%3A16%3A48.579Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08%3A16%3A48.579Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08%3A16%3A48.579Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08%3A16%3A48.579Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08%3A16%3A48.579Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--lead"
				>
					AutoCare Pro поєднує найновітніші технології та глибокі знання, щоб забезпечити найкраще обслуговування автомобілів. Ми готові до будь-якого завдання – від регулярного обслуговування до складних ремонтних робіт, завдяки нашій команді досвідчених професіоналів.
				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--lead" sm-text-align="left">
						Вибір AutoCare Pro означає, що ви обираєте надійність і довіру. Ми ставимо на перше місце ваш комфорт і спокій, знаючи, що ваше авто в надійних руках.
					</Text>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/garazh.jpg?v=2024-09-20T08:16:48.577Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					srcSet="https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/garazh.jpg?v=2024-09-20T08%3A16%3A48.577Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/garazh.jpg?v=2024-09-20T08%3A16%3A48.577Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/garazh.jpg?v=2024-09-20T08%3A16%3A48.577Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/garazh.jpg?v=2024-09-20T08%3A16%3A48.577Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/garazh.jpg?v=2024-09-20T08%3A16%3A48.577Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/garazh.jpg?v=2024-09-20T08%3A16%3A48.577Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22abe5d277001897f36d/images/garazh.jpg?v=2024-09-20T08%3A16%3A48.577Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-4">
			<Text
				margin="0px 0px 90px 0px"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--darkL1"
				border-color="--color-dark"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
			>
				Чому варто вибрати AutoCare Pro?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				lg-padding="0px 0 0px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							1
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Професійна команда
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Досвідчені спеціалісти, готові вирішувати будь-які завдання.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							2
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Гарантія якості
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Ми використовуємо тільки найякісніші матеріали.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 0 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							3
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Сучасне обладнання
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Використання передових технологій для вашого авто.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 0 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							4
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Клієнтоорієнтованість
						</Text>
						<Text margin="0 0 0 25px" font="normal 400 16px/1.5 --fontFamily-sans" color="--darkL2" text-align="left">
							Ми надаємо особливу увагу вашим потребам та бажанням, гарантуємо комфортне та надійне обслуговування.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Ctanew />
		<Components.Footer>
			<Override slot="box1" />
			<Override slot="text3" />
			<Override slot="link3">
				0955118688
			</Override>
			<Override slot="linkBox" />
			<Override slot="box3" />
			<Override slot="link" />
			<Override slot="box" />
			<Override slot="SectionContent" />
			<Override slot="text" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text4" />
			<Override slot="link4" />
		</Components.Footer>
		<Components.Policy />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});